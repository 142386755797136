import Axios from 'axios';
import { IGiveaway } from '@/types/IGiveaway';

interface GiveawayResponse {
  giveaway?: IGiveaway;
  error?: string;
}

export default async function joinGiveaway(
  giveawayId: number
) {
  if (process.env.NODE_ENV !== 'development') {
    return await Axios.post<GiveawayResponse>(
      `/api/giveaways/use`,
      { giveawayId: giveawayId }
    );
  }
}
