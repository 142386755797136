import { render, staticRenderFns } from "./TopPlace.vue?vue&type=template&id=35b156c1&scoped=true&"
import script from "./TopPlace.vue?vue&type=script&lang=ts&"
export * from "./TopPlace.vue?vue&type=script&lang=ts&"
import style0 from "./TopPlace.vue?vue&type=style&index=0&id=35b156c1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35b156c1",
  null
  
)

export default component.exports