
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ItemInfo from '@/components/Item/Item.vue';
import { Item } from '@/types/Item';
import { GetEventTopResponse } from '@/requests/Event/getTop/getEventTop';
import { getItemImage } from '@/helpers';

@Component({
  components: {ItemInfo},
})
export default class EventBanner extends Vue {
  get items(): Item[] {
    return [
      ...(this.$store.state.event.top['day']?.data?.prizeItems[0] ? [{
        id: 1,
        name: 'Каждый день',
        image: getItemImage(this.$store.state.event.top['day'].data.prizeItems[0]),
        type: 'money',
        color: this.$store.state.event.top['day'].data?.prizeItems[0]?.color,
        price: 0
      } as Item] : [undefined]),
      ...(this.$store.state.event.top['month']?.data?.prizeItems[0] ? [{
        id: 1,
        name: 'Каждый месяц',
        image: getItemImage(this.$store.state.event.top['month'].data.prizeItems[0]),
        type: 'money',
        color: this.$store.state.event.top['month'].data?.prizeItems[0]?.color,
        price: 0
      } as Item] : [undefined]),
    ];
  }

  get randomArcane(): Item {
    const items = [...this.rewardItems()];
    items.shift();

    const randomIdx = Math.floor(Math.random() * items.length);

    return items[randomIdx];
  }

  rewardItems(): Item[] {
    return this.$store.state.event.tasks.data.rewardItems;
  }
}
