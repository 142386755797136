
import { Component, Prop, Vue } from 'vue-property-decorator';

import { EventTask } from '@/types/Event';
import { getCaseValuablePrice } from '@/helpers';
import CaseImage from '@/components/Case/CaseImage.vue';

@Component({
  components: {CaseImage},
})
export default class TaskPreview extends Vue {
  @Prop() task: EventTask;
  @Prop() currentTaskIndex: number;
  @Prop() selectedTaskIndex: number;
  @Prop() index: number;

  get shouldShowRunes() {
    return [0, 2, 3].includes(this.index);
  }

  get withArcane() {
    return [15, 30, 45].includes(this.index + 1);
  }

  get isMegaGift() {
    return this.index + 1 === 60;
  }

  getCaseValuablePrice(casePrice: number) {
    return getCaseValuablePrice(casePrice);
  }
}
