
import { Component, Prop, Vue } from 'vue-property-decorator';
import CaseImage from '@/components/Case/CaseImage.vue';
import VLazyImage from 'v-lazy-image/v2';
import ItemInfo from '@/components/Item/Item.vue';
import { IGiveaway } from '@/types/IGiveaway';
import Countdown from '@/components/Event/Countdown.vue';
import { EventTopFilter } from '@/requests/Event/getTop/getEventTop';
import LoginGeneralButton from '@/components/Buttons/LoginGeneralButton.vue';
import ButtonGiveaway from '@/components/Giveaway/ButtonGiveaway.vue';
import Button from '@/components/Buttons/Button.vue';
import joinGiveaway from '@/requests/Giveaway/joinGiveaway';

@Component({
  components: {Button, ButtonGiveaway, LoginGeneralButton, Countdown, ItemInfo, CaseImage, VLazyImage}
})
export default class Giveaway extends Vue {
  @Prop() giveaway: IGiveaway;
  filter: EventTopFilter = 'day';
  blockJoinGiveaway: boolean = false;

  get isOnMainPage(): boolean {
    return this.$route.path === '/';
  }

  get forceUseGiveaway(): boolean {
    return this.isOnMainPage && !this.user && this.giveaway.type === 'completeEventTasks';
  }

  async joinGiveaway(giveawayId: number) {
    if (this.blockJoinGiveaway) {
      return;
    }
    try {
      this.blockJoinGiveaway = true;
      const response = await joinGiveaway(giveawayId);
      if (response?.data?.error) {
        throw Error(response.data.error);
      }
      this.$store.dispatch('giveaways/joinGiveaway', {giveawayId: this.giveaway.id});
    } catch (err) {
      console.error(err);

      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'error',
        title: 'Розыгрыш',
        text:
            err.response && err.response.data && err.response.data.error
                ? err.response.data.error
                : err.message || 'Произошла неизвестная ошибка при участии в розыгрыше. Пожалуйста, попробуйте позже.'
      });
    } finally {
      this.blockJoinGiveaway = false;
    }
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
        ? this.$store.state.user
        : null;
  }

  get promotion() {
    if (this.giveaway.conditions?.usePromotion) {
      const promotions = this.$store.state.promotions.promotions;
      return promotions.find(({id}) => this.giveaway.conditions?.usePromotion === id) || null;
    }
    return null;
  }
}
