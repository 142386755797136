var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._g({staticClass:"task-full-data"},_vm.$listeners),[(!_vm.fakeVersion)?_c('div',{staticClass:"wrapper"},[(_vm.isDebugMode)?_c('Button',{staticClass:"debug-button",attrs:{"type":"green","text":"Debug"},nativeOn:{"click":function($event){return _vm.onTaskCompleted.apply(null, arguments)}}}):_vm._e(),_c('div',{staticClass:"task-full-data__grid-wrap"},[_c('div',{staticClass:"task-full-data__task-plus-awards"},[_c('div',[_c('TaskInfo',{key:_vm.currentTaskIndex,attrs:{"taskIndex":_vm.currentTaskIndex,"task":_vm.tasks[_vm.currentTaskIndex]},on:{"taskCompleted":_vm.onTaskCompleted}})],1),_c('div',[_c('Reward',{attrs:{"taskRewards":{
              ..._vm.tasks[_vm.currentTaskIndex]?.rewards,
              case: _vm.caseToDisplayInRewards,
            },"arcaneItems":_vm.arcaneItems,"finalItems":_vm.finalItems,"currentTaskIndex":_vm.currentTaskIndex,"lvlUntilArcane":_vm.lvlUntilArcane,"lvlUntilHook":_vm.lvlUntilHook}})],1)]),(_vm.currentTaskIndex > -1)?_c('div',{staticClass:"task-full-data__before-wrap"},[(_vm.lvlUntilArcane > 0)?_c('div',{staticClass:"task-full-data__before --arcane"},[_vm._v(" До случайной "),_c('span',{staticClass:"_word"},[_vm._v("арканы")]),_vm._v(" осталось"),_c('span',{staticClass:"_digit"},[_vm._v(_vm._s(_vm.lvlUntilArcane))]),_vm._v(_vm._s(_vm.ruDec(_vm.lvlUntilArcane, ["задание", "задания", "заданий"]))+" ")]):_vm._e(),(_vm.lvlUntilHook > 0)?_c('div',{staticClass:"task-full-data__before --hook"},[_vm._v(" до "),_c('span',{staticClass:"_word"},[_vm._v("мега сундука")]),_vm._v(" осталось"),_c('span',{staticClass:"_digit"},[_vm._v(_vm._s(_vm.lvlUntilHook))]),_vm._v(_vm._s(_vm.ruDec(_vm.lvlUntilHook, ["задание", "задания", "заданий"]))+" ")]):_vm._e()]):_vm._e()])],1):_vm._e(),_c('div',{class:{
      'tasks-row-wrap': true,
      'tasks-row-wrap--agile': true,
      'tasks-row-wrap--fake': _vm.fakeVersion,
    }},[_c('InlineSvg',{staticClass:"tasks-row-wrap__arrow --left",attrs:{"src":require('@/assets/img/slider-arrow-left.svg')},on:{"click":function($event){return _vm.moveSlider('left')}}}),_c('InlineSvg',{staticClass:"tasks-row-wrap__arrow --right",attrs:{"src":require('@/assets/img/slider-arrow-right.svg')},on:{"click":function($event){return _vm.moveSlider('right')}}}),_c('div',{staticClass:"tasks-row tasks-row--agile"},[_c('agile',{ref:"agileCarousel",attrs:{"options":_vm.agileOptions}},[(_vm.windowWidth > 1280)?_c('TaskPreview',{staticClass:"slide",attrs:{"index":-1,"task":null,"currentTaskIndex":_vm.currentTaskIndex,"selectedTaskIndex":_vm.currentTaskIndex}}):_vm._e(),_vm._l((_vm.tasks),function(task,index){return _c('TaskPreview',{key:index,class:['slide', index === _vm.tasks.length - 1 ? '--last' : ''],attrs:{"task":task,"currentTaskIndex":_vm.currentTaskIndex,"selectedTaskIndex":_vm.currentTaskIndex,"index":index}})})],2)],1),_c('div',{staticClass:"tasks-row-progress-line"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }